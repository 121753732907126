<template>
  <section :class="[$style.promo, 'section container']" data-aos="fade-top" data-aos-delay="0">
    <div :class="$style.wrapper">
      <div :class="$style.promoBcg">
        <img src="/images/HomePromoBcg.svg" alt="" />
      </div>
      <div :class="$style.content">
        <UiIconsLogoLarge :class="$style.logo" />
        <div :class="$style.title">{{ t('home.promo.title') }}</div>
        <div :class="$style.caption">{{ t('home.promo.text') }}</div>
        <UiButton :to="localePath('/prices')">
          <template #label>
            {{ t('home.promo.button') }}
          </template>
          <template #preppendIcon>
            <UiIconsArrowTopRight />
          </template>
        </UiButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style lang="scss" module>
.promo {
  position: relative;

  &Bcg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 48px;
    overflow: hidden;

    @media (max-width: 767px) {
      border-radius: 24px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.wrapper {
  position: relative;
}

.logo {
  width: 84px;
  height: auto;
  margin-bottom: 80px;

  @media (max-width: 1023px) {
    width: 66px;
    margin-bottom: 45px;
  }

  @media (max-width: 767px) {
    margin-bottom: 96px;
  }
}

.content {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 764px;
  margin: auto;
  padding: 58px 16px 86px 16px;
  color: #fff;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 48px 16px 62px 16px;
  }

  @media (max-width: 767px) {
    padding-bottom: 126px;
  }
}

.title {
  color: #FFF;
  text-align: center;
  font-family: 'Gilroy';
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 17px;

  @media (max-width: 1023px) and (min-width: 768px) {
    font-size: 40px;
    max-width: 426px;
  }

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

.caption {
  margin-bottom: 56px;
  opacity: 0.8;

  @media (max-width: 767px) {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 68px;
  }
}
</style>