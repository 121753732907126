<template>
  <section class="section">
    <div :class="$style.blog">
      <div :class="[$style.wrapper, 'container']">
        <div :class="$style.header">
          <div :class="$style.headerContent">
            <div :class="$style.label" data-aos="fade-top" data-aos-delay="0">{{ t('home.blog.upperTitle') }}</div>
            <h2 :class="['h1', $style.title]" data-aos="fade-top" data-aos-delay="200">{{ t('home.blog.title') }}</h2>
            <div data-aos="fade-top" data-aos-delay="400">
              {{ t('home.blog.text') }}
            </div>
          </div>

          <UiButton
            :to="localePath('/blog')"
            type="circle"
            :class="$style.action"
             data-aos="fade-top" data-aos-delay="600"
          >
            <template #label>
              {{ t('home.blog.button') }}
            </template>
            <template #preppendIcon>
              <UiIconsArrowRight />
            </template>
          </UiButton>
        </div>
      </div>

      <Swiper
        :class="['cs', $style.swiper]"
        data-aos="fade-top" data-aos-delay="800"
        :modules="[SwiperNavigation]"
        :slides-per-view="'auto'"
        :space-between="10"
        :navigation="{
          prevEl: '.prev',
          nextEl: '.next',
        }"
        :breakpoints="{
          1024: {
            spaceBetween: 22,
          },
          320: {
            spaceBetween: 16,
          }
        }"
      >
        <SwiperSlide
          v-for="(post, index) of listOfBlog"
          :key="index"
          :class="$style.item"
        >
          <nuxt-link :to="localePath(`/blog/${post.id}`)" :class="$style.itemThumb">
            <img :src="post.featured_image_url || '/images/topPost.webp'" alt="">
          </nuxt-link>

          <nuxt-link :to="localePath(`/blog/${post.id}`)" :class="$style.itemTitle">{{ post.title.rendered }}</nuxt-link>
          <div :class="$style.itemText" v-html='post.excerpt.rendered'></div>
          <nuxt-link :to="localePath(`/blog/${post.id}`)" :class="$style.itemDate">{{ $moment(post.date).format('DD.MM.YYYY') }}</nuxt-link>
        </SwiperSlide>

        <div
          :class="$style.nav"
        >
          <div :class="[$style.prev, 'prev']">
            <UiIconsArrowLeft />
          </div>
          <div :class="[$style.next, 'next']">
            <UiIconsArrowRight />
          </div>

          <UiButton
            :to="localePath('/blog')"
            type="circle"
            :class="$style.action"
          >
            <template #label>
              See More
            </template>
            <template #preppendIcon>
              <UiIconsArrowRight />
            </template>
          </UiButton>
        </div>
      </Swiper>
    </div>
  </section>
</template>

<script setup lang="ts">
const { locale } = useI18n();
const { t } = useI18n();

const { $moment } = useNuxtApp();

const page = ref<number>(1);
const totalPages = useState('totalPages', () => 0);

const getPosts = async (page: number, search: string = ''): Promise<Record<any, any>[]> => {
  try {
    const response = await fetch(`https://wp.stageaqum.org/wp-json/wp/v2/posts?meta_key=lang&meta_value=${locale.value}&search=${search}&per_page=12&page=${page}`, {
      method: 'GET',
    });

    const totalPagesHeader = response.headers.get('X-WP-TotalPages');

    if (totalPagesHeader) {
      totalPages.value = parseInt(totalPagesHeader, 10);
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
};

const { data: listOfBlog, } = useAsyncData(
  'listOfDownloads',
  () => getPosts(page.value),
  {
    watch: [page],
  },
);

</script>

<style lang="scss" module>
  .blog {
    background: #F8F8F8;
    padding: 60px 0 70px 0;
    margin-bottom: -34px;
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 48px 0 0 0;
    padding: 0 16px;
    gap: 20px;

    @media (max-width: 767px) {
      margin-top: 24px;
      justify-content: flex-start;
    }

    > div {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 45px;
      background: #fff;
      color: #121E29;
      transition: 200ms ease;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.8;
      }
    }

    svg {
      width: 12px;
      height: 12px;
    }

    .action {

      @media (min-width: 768px) {
        display: none;
      }

      @media (max-width: 767px) {
        margin-left: auto;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    background: #FFF;
    padding: 12px 12px 16px 12px;
    text-decoration: none;
    height: auto;
    cursor: pointer;

    @media (min-width: 1024px) {
      width: 470px;

      &:nth-child(1) {
        margin-left: calc((100vw - 1332px) / 2 + 16px);
      }
    }

    @media (max-width: 1023px) {
      width: 360px;

      &:nth-child(1) {
        margin-left: 16px;
      }
    }

    @media (max-width: 767px) {
      width: calc(100vw - 32px)
    }

    * {
      text-decoration: none;
    }

    &Title {
      display: flex;
      color: #121E29;
      font-family: 'Gilroy';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 7px;
      margin-top: 18px;
    }

    &Text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      margin-bottom: auto;

      @media (max-width: 1023px) {
        -webkit-line-clamp: 8;
      }

      @media (max-width: 767px) {
        -webkit-line-clamp: 4;
      }
    }

    &Date {
      display: flex;
      margin-top: 8px;
      color: #71787F;
      font-size: 14px;
    }

    &Cat {
      z-index: 2;
      position: absolute;
      top: 22px;
      left: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 28px;
      padding: 7px 12px;
      border-radius: 14px;
      background: rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(7.5px);
      color: #fff;
      font-family: 'Gilroy';
      font-size: 12px;
      font-weight: 400;

      svg {
        flex-shrink: 0;
        width: 10px;
        height: auto;
      }
    }

    &Thumb {
      display: block;
      position: relative;
      border-radius: 24px;
      overflow: hidden;
      height: 250px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 50px;

    &Content {
      max-width: 520px;

      @media (max-width: 1023px) {
        max-width: 376px;
      }
    }

    .action {

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .label {
    color: #71787F;
    font-weight: 200;
    margin-bottom: 18px;
  }

  h2.title {
    margin-bottom: 4px;

    @media (max-width: 1023px) {
      font-size: 40px;
    }
  }
</style>