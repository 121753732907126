<template>
  <div class="section">
    <div :class="[$style.cover, 'container']">
      <div :class="$style.preview" data-aos="fade-top" data-aos-delay="0">
        <div :class="$style.label">{{ t('home.solution.upperTitle') }}</div>
        <div :class="[$style.previewTitle, 'h3']">{{ t('home.solution.title') }}</div>

        <UiIconsLogoPart :class="$style.logoPart" />
      </div>
      <div
        v-for="({
          image,
          title,
          caption,
        }, index) of list"
        :key="index"
        :class="$style.item"
         data-aos="fade-top" :data-aos-delay="index * 200"
      >
        <img
          :class="$style.set"
          :src="image"
        />

        <div :class="$style.title">{{ title }}</div>
        <div :class="$style.captio">{{ caption }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface ItemParams {
  image: string,
  title: string,
  caption: string,
}

const { t } = useI18n();

const list = computed<ItemParams[]>(() => [
  {
    image: '/images/core/core-1.webp',
    title: t('home.solution.tiles.1.title'),
    caption: t('home.solution.tiles.1.text'),
  }, {
    image: '/images/core/core-2.webp',
    title: t('home.solution.tiles.2.title'),
    caption: t('home.solution.tiles.2.text'),
  }, {
    image: '/images/core/core-3.webp',
    title: t('home.solution.tiles.3.title'),
    caption: t('home.solution.tiles.3.text'),
  }, {
    image: '/images/core/core-4.webp',
    title: t('home.solution.tiles.4.title'),
    caption: t('home.solution.tiles.4.text'),
  }, {
    image: '/images/core/core-5.webp',
    title: t('home.solution.tiles.5.title'),
    caption: t('home.solution.tiles.5.text'),
  },
])
</script>

<style lang="scss" module>
  .cover {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 26px;

    @media (max-width: 1023px) and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 16px;
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
  }

  .preview,
  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 29px 29px 43px 29px;
  }

  .preview {
    padding-bottom: 0;

    @media (max-width: 1023px) {
      padding-left: 0;
      padding-top: 0;
    }

    &Title {
      margin-bottom: 80px;
    }
  }

  .label {
    color: #71787F;
    font-weight: 200;
    margin-bottom: 18px;
  }

  .logoPart {
    margin-top: auto;
    width: 90px;
    height: auto;
  }

  .item {
    border-radius: 24px;
    border: 1px solid #F0F0F0;

    @media (max-width: 767px) {
      height: 380px;
    }
  }

  .set {
    width: auto;
    height: 61px;
  }

  .title {
    color: #121E29;
    font-family: 'Gilroy';
    font-size: 36px;
    font-weight: 400;
    margin: auto 0 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px dashed #F0F0F0;
    width: 100%;
  }
</style>