<template>
  <HomeDiscount />
  <HomeTopHead />
  <HomeBanner />
  <HomeCover />
  <HomeFeatures />
  <HomePromo />
  <HomeProvides />
  <HomeTestimonials />
  <HomeMobileApp />
  <HomeBlog />
  <Subscribe />
</template>

<script setup lang="ts">
const { t } = useI18n();

useHead({
  title: t('meta.index.title'),
  meta: [
    { name: 'description', content: t('meta.index.description') },

    { property: 'og:title', content: t('meta.index.ogTitle') },
    { property: 'og:description', content: t('meta.index.ogDescription') },

    { property: 'twitter:title', content: t('meta.index.twitterTitle') },
    { property: 'twitter:description', content: t('meta.index.twitterTitle') },
    { property: 'twitter:card', content: 'summary_large_image' },
  ],
});
</script>