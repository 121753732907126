<template>
  <section :class="[$style.provides, 'section provides']">
    <div :class="$style.wrapper">
      <div class="container">
        <div :class="$style.content">
          <div :class="$style.header">
            <h2 :class="[$style.title, 'h1 ml3']">
              <span class="letters">{{ t('home.provides.title') }}</span>
            </h2>
            <div :class="$style.caption" data-aos="fade-in" data-aos-delay="0">
              {{ t('home.provides.text') }}

              <UiButton :to="localePath('/auth/signup')" :class="$style.action">
                <template #label>
                  {{ t('home.provides.button') }}
                </template>
                <template #preppendIcon>
                  <UiIconsArrowTopRight />
                </template>
              </UiButton>
            </div>
          </div>

          <div :class="$style.list">
            <div
              v-for="({ title, icon }, index) of list"
              :key="index"
              :class="$style.item"
              data-aos="fade-top" :data-aos-delay="index * 200"
            >
              <div :class="$style.itemTitle">{{ title }}</div>
              <img :src="icon" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div :class="$style.line">
        <div
          v-for="counter in 20"
          :key="counter"
          :class="$style.step"
        >
          SECURE <img src="/images/dividerLine.svg" />
          FAST <img src="/images/dividerLine.svg" />
          FULL <img src="/images/dividerLine.svg" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
interface ItemParams {
  title: string,
  icon: string,
}

const { t } = useI18n();

const list = computed<ItemParams[]>(() => [
  {
    title: t('home.provides.tiles.1'),
    icon: '/images/provides/p1.svg',
  }, {
    title: t('home.provides.tiles.2'),
    icon: '/images/provides/p2.svg',
  }, {
    title: t('home.provides.tiles.3'),
    icon: '/images/provides/p3.svg',
  }, {
    title: t('home.provides.tiles.4'),
    icon: '/images/provides/p4.svg',
  },
]);

import { observerAnimate } from '@/utils/observer.js';

const animate = () => {
  const { $anime } = useNuxtApp();
  const textWrapper = document.querySelectorAll('.ml3 .letters');

  if (textWrapper.length) {
    textWrapper.forEach((tw) => {
      if (tw && tw.innerHTML && tw.textContent) {
        tw.innerHTML = tw.textContent.replace(/\S/g, "<span class='letter'>$&</span>") || '';
      }
    });
  }

  $anime.timeline({
    easing: 'easeOutExpo',
    duration: 300
  }).add({
    targets: '.ml3 .letter',
    translateY: [-100,0],
    opacity: [0,1],
    easing: "easeOutExpo",
    duration: 1400,
    delay: (el, i) => 30 * i
  });
  $anime.timeline({
    easing: 'easeOutExpo',
    duration: 275
  }).add({
    targets: '.ml3',
    opacity: 1,
    duration: 200,
    easing: "easeOutExpo",
    delay: 150
  });
};

onMounted(() => {
  if (import.meta.client) {
    observerAnimate('.provides', animate);
  }
});
</script>

<style lang="scss" module>
  .provides {

  }

  .action {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .line {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 56px;
    color: #A0A5A9;
    font-family: 'Gilroy';
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px;

    @media (max-width: 767px) {
      font-size: 52px;
    }

    img {
      flex-shrink: 0;
      width: 59px;
      height: auto;
      margin: 8px 20px 0 9px;
    }
  }

  .step {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    animation: marquee 5s linear infinite;
  }

  .wrapper {
    border-radius: 24px;
    background: #F7F7F7;
    overflow: hidden;
    padding: 90px 0 64px 0;

    @media (max-width: 1023px) {
      padding: 46px 0 42px 0;
    }
  }

  .content {
    border-radius: 32px;
    background: #FFF;
    padding: 56px 55px 74px 55px;

    @media (max-width: 1023px) {
      padding: 42px;
    }
  }

  .header {
    display: flex;
    gap: 64px;
    margin-bottom: 54px;

    @media (max-width: 1023px) {
      flex-direction: column;
      gap: 15px;
      margin-bottom: 48px;
    }
  }

  .title {
    flex: 1;
    overflow: hidden;

    @media (max-width: 1023px) {
      font-size: 40px;
    }
  }

  .caption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    flex: 1;

    @media (max-width: 1023px) {
      max-width: 548px;
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    @media (max-width: 1023px) {
      gap: 22px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    background: #FBFCFF;
    text-align: center;
    padding: 36px 16px 40px 16px;

    &Title {
      max-width: 200px;
      color: #121E29;
    }

    img {
      width: auto;
      height: 168px;
      margin-top: 32px;
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
</style>