<template>
  <section :class="['section container', $style.testimonials]">
    <div :class="$style.header" data-aos="fade-top" data-aos-delay="0">
      <h2 :class="$style.label">{{ t('home.testimonials.upperTitle') }}</h2>
      <div class="h2">{{ t('home.testimonials.title') }}</div>
    </div>
  </section>

  <div :class="$style.swiperContainer">
    <Swiper
      :class="['cs', $style.swiper]"
      :modules="[SwiperNavigation]"
      :slides-per-view="'auto'"
      :space-between="10"
      :navigation="{
        prevEl: '.prev',
        nextEl: '.next',
      }"
    >
      <SwiperSlide
        v-for="(item, index) in reviews"
        :key="index"
        :class="$style.item"
      >
        <div :class="$style.itemPName">{{ item.name }}</div>
        <div
          :class="$style.itemCaption"
        >
          {{ item.text }}
        </div>
        <div :class="$style.itemFooter">
          <div :class="$style.itemAvatar">
            <img src="/images/avatar.png" />
          </div>
          <div :class="$style.itemParams">
            <div :class="$style.itemName">{{ item.name }}</div>
            <!-- <div :class="$style.itemPosition">Head of Liquidity Traffic</div> -->
          </div>
          <UiIconsQuote  :class="$style.itemQuote" />
        </div>
      </SwiperSlide>

      <div
        :class="$style.nav"
      >
        <div :class="[$style.prev, 'prev']">
          <UiIconsArrowLeft />
        </div>
        <div :class="[$style.next, 'next']">
          <UiIconsArrowRight />
        </div>
      </div>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
const { t, locale, messages } = useI18n();

const reviews = messages.value[locale.value].reviews;

</script>

<style lang="scss" module>
  .testimonials {

  }

  .swiperContainer {

    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }

  .swiper {
    position: relative;

    &:before,
    &:after {
      content: '';
      z-index: 2;
      position: absolute;
      user-select: none;
      pointer-events: none;
      top: 0;
      height: 100%;
      width: 160px;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    &:before {
      left: 0;
      background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    &:after {
      right: 0;
      transform: scaleX(-1);
      background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }
  }

  .nav {
    display: flex;
    justify-content: center;
    margin: 48px 0 0 0;
    gap: 20px;

    > div {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 45px;
      background: #020F2D;
      color: #fff;
      transition: 200ms ease;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.8;
      }
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .header {
    text-align: center;
    margin-bottom: 52px;
    max-width: 510px;
    margin: 0 auto 38px auto;
  }

  .label {
    margin: 0;
    padding: 0;
    color: #71787F;
    text-align: center;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    font-size: 16px;

    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }

  .item {
    padding: 24px;
    border-radius: 24px;
    border: 1px solid #F0F0F0;
    background: #FFF;
    @media (min-width: 1024px) {
      width: 325px;
    }

    @media (max-width: 1023px) {
      width: 465px;
    }

    @media (max-width: 767px) {
      width: calc(100vw - 32px);
    }

    &PName {
      font-size: 12px;
      margin-bottom: 16px;
    }

    &Caption {
      margin-bottom: 45px;
      height: 290px;
      overflow: hidden;
    }

    &Footer {
      display: flex;
      align-items: center;
    }

    &Avatar {
      flex-shrink: 0;
      width: 45px;
      height: 45px;
      margin-right: 12px;
      border-radius: 45px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &Params {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &Name {
      color: #121E29;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &Position {
      color: #71787F;
      font-weight: 200;
      font-size: 10px;
    }

    &Quote {
      margin-left: auto;
      color: #8D9398;
      width: 41px;
      height: auto;
    }
  }
</style>