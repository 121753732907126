<template>
  <section :class="['section container', $style.mobileApp]">
    <div :class="$style.content">
      <div :class="$style.label" data-aos="fade-top" data-aos-delay="0">{{ t('home.mobileApp.upperTitle') }}</div>
      <h2 :class="['h2', $style.title]" data-aos="fade-top" data-aos-delay="300">{{ t('home.mobileApp.title') }}</h2>
      <div data-aos="fade-top" data-aos-delay="600">
        {{ t('home.mobileApp.text') }}
      </div>
      <div :class="$style.actions">
        <UiButton
          color="gray"
          to="https://play.google.com/store/apps/details?id=com.aqum.app"
          blank
          data-aos="fade-top" data-aos-delay="900"
        >
          <template #label>
            {{ t('home.mobileApp.buttons.google') }}
          </template>
          <template #preppendIcon>
            <UiIconsGooglePlay />
          </template>
        </UiButton>
        <UiButton
          color="gray"
          to="https://apps.apple.com/us/app/aqum/id6447129587"
          blank
          data-aos="fade-top" data-aos-delay="1200"
        >
          <template #label>
            {{ t('home.mobileApp.buttons.apple') }}
          </template>
          <template #preppendIcon>
            <UiIconsApple />
          </template>
        </UiButton>
      </div>
    </div>
    <div :class="$style.banner" data-aos="fade-top" data-aos-delay="1000">
      <img src="/images/mobileApp.webp" alt="">
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style lang="scss" module>
  .mobileApp {
    display: flex;
    align-items: flex-end;
    gap: 78px;

    @media (max-width: 1023px) {
      gap: 16px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 36px;
    }
  }

  .content,
  .banner {
    flex: 1;
  }

  .content {
    padding-bottom: 70px;

    @media (max-width: 1023px) {
      padding-bottom: 0;
    }
  }

  .label {
    color: #71787F;
    font-weight: 200;
    margin-bottom: 26px;

    @media (max-width: 1023px) {
      margin-bottom: 18px;
    }
  }

  h2.title {
    margin-bottom: 26px;
  }

  .actions {
    display: flex;
    gap: 20px;
    margin-top: 92px;

    @media (max-width: 1023px) {
      flex-direction: column;
      margin-top: 28px;
      gap: 16px;
    }
  }

  .banner {

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
</style>