export const observerAnimate = (elementObserve: string, method: Function) => {
  if (!elementObserve) return;
  let options = {
    root: null,
    rootMargin: '-150px',
  };

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
        method?.();
        entry.target.classList.add('animated');
        observer.unobserve(entry.target);
      }
    });
  }, options);

  const elements = document.querySelectorAll(elementObserve);

  if (elements) {
    elements.forEach((el: Element) => {
      observer.observe(el);
    })
  }
};
