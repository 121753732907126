<template>
  <section :class="[$style.discount, 'container']">
    <div :class="$style.wrapper">
      <div :class="$style.discountBcg">
        <picture>
          <source srcset="/images/discount/discount-m.webp" media="(max-width: 1023px)">
          <img src="/images/discount/discount.webp" alt=""/>
        </picture>
      </div>
      <div :class="$style.content">
        <div :class="$style.label">{{ t('home.discount.label') }}</div>
        <div :class="$style.title">{{ t('home.discount.title') }}</div>
        <div :class="$style.promo">
          <div :class="$style.label">{{ t('home.discount.promo') }}</div>
          <div :class="$style.code">BLESSADS</div>
        </div>
        <UiButton
          :to="localePath('/downloads')"
          color="white"
        >
          <template #label>
            {{ t('home.discount.button') }}
          </template>
        </UiButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style lang="scss" module>
  .discount {
    position: relative;
    padding-bottom: 60px;

    @media (max-width: 1023px) {
      padding-bottom: 30px;
    }

    &Bcg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 24px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .wrapper {
    position: relative;
  }

  .content {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 60px 50px 64px 53px;
    color: #fff;
    text-align: center;

    @media (max-width: 1023px) {
      padding: 33px 72px 50px 72px;
    }

    @media (max-width: 767px) {
      padding: 25px;
    }
  }

  .label {
    font-size: 29px;
    margin-bottom: 23px;
    font-style: normal;
    font-weight: 100;
    line-height: 31.129px;

    @media (max-width: 1023px) {
      font-size: 14px;
      margin-bottom: 14px;
      max-width: 500px;
      line-height: 15.395px;
    }

  }

  .title {
    margin-bottom: 125px;
    color: #FFF;
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 49.772px;
    max-width: 1000px;

    @media (max-width: 1023px) {
      font-size: 23px;
      line-height: 24px;
      margin-bottom: 43px;
    }

    @media (max-width: 425px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;
    }

  }

  .promo {
    margin-bottom: 53px;

    @media (max-width: 1023px) {
      margin-bottom: 35px;
    }

    .label {
      margin-bottom: 0;
      font-size: 32px;
      font-style: normal;
      font-weight: 100;

      @media (max-width: 1023px) {
        font-size: 15px;
      }
    }

    .code {
      font-size: 87px;
      font-style: normal;
      font-weight: 600;
      line-height: 93px;

      @media (max-width: 1023px) {
        font-size: 43px;
        line-height: 46px;
      }
    }
  }
</style>