<template>
  <div :class="[$style.topHead, 'homeHead container']">
    <div :class="$style.label" data-aos="fade-right">{{ t('home.header.upperTitle') }}</div>
    <h1 :class="['h1 ml3', $style.title]" data-aos="fade-right" data-aos-delay="150">
      <span class="letters">{{ t('home.header.title') }}</span>
    </h1>
    <div :class="$style.caption" data-aos="fade-left" data-aos-delay="150">
      <div :class="$style.text" v-html="t('home.header.text')" />

      <UiButton @click.prevent="buttonAction" :class="$style.action">
        <template #label>
          {{ t('home.header.button') }}
        </template>

        <template #preppendIcon>
          <UiIconsArrowTopRight />
        </template>
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const router = useRouter();
const localePath = useLocalePath();

const buttonAction = () => {
  fbq('track', 'InitiateCheckout');
  router.push(localePath('/auth/signup'));
};

const animate = () => {
  const { $anime } = useNuxtApp();
  const textWrapper = document.querySelectorAll('.ml3 .letters');

  if (textWrapper.length) {
    textWrapper.forEach((tw) => {
      if (tw && tw.innerHTML && tw.textContent) {
        tw.innerHTML = tw.textContent.replace(/\S/g, "<span class='letter'>$&</span>") || '';
      }
    });
  }

  $anime.timeline({
    easing: 'easeOutExpo',
    duration: 300
  }).add({
    targets: '.ml3 .letter',
    opacity: [0,1],
    easing: "easeInOutQuad",
    duration: 2250,
    delay: (el, i) => 45 * (i+1)
  });
  $anime.timeline({
    easing: 'easeOutExpo',
    duration: 275
  }).add({
    targets: '.ml3',
    opacity: 1,
    duration: 200,
    easing: "easeOutExpo",
    delay: 150
  });
};

onMounted(() => {
  if (import.meta.client) {
    observerAnimate('.homeHead', animate);
  }
});
</script>

<style lang="scss" module>
  .topHead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 46px;
    gap: 0 76px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: start;
      gap: 15px;
      margin-bottom: 34px;
    }
  }

  .action {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .label {
    margin-bottom: 4px;
    color: #71787F;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    width: 100%;
  }

  h1.title {
    flex: 1;
    margin: 0;
    padding: 0;

    @media (min-width: 1024px) {
      line-height: 86px;
    }
  }

  .caption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    gap: 36px;
  }

  .text {
    max-width: 512px;
  }
</style>