<template>
  <section :class="[$style.features, 'section container']">
    <div :class="$style.header" data-aos="fade-top" data-aos-delay="0">
      <h2 :class="$style.label">{{ t('home.features.upperTitle') }}</h2>
      <div class="h2">{{ t('home.features.title') }}</div>
    </div>

    <Swiper
      :class="['cs', $style.swiper]"
      :modules="[SwiperScrollbar, SwiperFreeMode, SwiperGrid, SwiperMousewheel]"
      :space-between="26"
      :slides-per-view="'auto'"
      :direction="'horizontal'"
      :free-mode="true"
      :mousewheel="{
        forceToAxis: false,
        sensitivity: 1,
        releaseOnEdges: true,
      }"
      :breakpoints="{
        1024: {
          spaceBetween: 26,
        },
        320: {
          spaceBetween: 16,
        }
      }"
      :scrollbar="{ draggable: true }"
    >
      <SwiperSlide :class="[$style.item, $style.dnd]">
        <div :class="$style.itemTitle">{{ t('home.features.slider.1.title') }}</div>
        <div :class="$style.itemCaption">
          {{ t('home.features.slider.1.text') }}
        </div>
        <img src="/images/features/dnd.svg" alt="" />
      </SwiperSlide>
      <SwiperSlide :class="[$style.item, $style.mApp]">
        <div :class="$style.itemTitle">{{ t('home.features.slider.2.title') }}</div>
        <div :class="$style.itemCaption">
          {{ t('home.features.slider.2.text') }}
        </div>
        <img src="/images/features/mApp.webp" alt="" />
      </SwiperSlide>
      <SwiperSlide :class="[$style.item, $style.team]">
        <div :class="$style.itemTitle">{{ t('home.features.slider.3.title') }}</div>
        <div :class="$style.itemCaption">
          {{ t('home.features.slider.3.text') }}
        </div>
        <img src="/images/features/team.webp" alt="" />
      </SwiperSlide>
      <SwiperSlide :class="[$style.item, $style.mApp]">
        <div :class="$style.itemTitle">{{ t('home.features.slider.4.title') }}</div>
        <div :class="$style.itemCaption">
          {{ t('home.features.slider.4.text') }}
        </div>
        <img src="/images/features/canvas.webp" alt="" />
      </SwiperSlide>
      <SwiperSlide :class="[$style.item, $style.bookmarks]">
        <div :class="$style.itemTitle">{{ t('home.features.slider.5.title') }}</div>
        <div :class="$style.itemCaption">
          {{ t('home.features.slider.5.text') }}
        </div>
        <img src="/images/features/bookmarks.webp" alt="" />
      </SwiperSlide>
      <SwiperSlide :class="[$style.item, $style.mApp]">
        <div :class="$style.itemTitle">{{ t('home.features.slider.6.title') }}</div>
        <div :class="$style.itemCaption">
          {{ t('home.features.slider.6.text') }}
        </div>
        <picture>
          <source srcset="/images/features/extensions-m.svg" media="(max-width: 1023px)">
          <img src="/images/features/extensions.svg" alt=""/>
        </picture>
      </SwiperSlide>
    </Swiper>

    <div class="swiper-scrollbar"></div>
  </section>
</template>

<script setup lang="ts">
  const { t } = useI18n();
</script>

<style lang="scss" module>
  .features {

  }

  .header {
    text-align: center;
    margin-bottom: 52px;
  }

  .label {
    margin: 0;
    padding: 0;
    color: #71787F;
    text-align: center;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    font-size: 16px;

    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }

  .item {
    position: relative;
    border-radius: 24px;
    background: radial-gradient(43.06% 47.24% at 50.07% 68.14%, rgba(247, 247, 247, 0.00) 0%, #F7F7F7 100%);
    overflow: hidden;

    @media (min-width: 1024px) {
      height: 543px;
      padding: 46px 39px 0 39px;
    }

    @media (max-width: 1023px) {
      height: 446px;
      padding: 26px 26px 0 26px;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
    }

    &.dnd {
      @media (max-width: 1023px) {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }

    &.team,
    &.bookmarks {
      @media (max-width: 1023px) {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: left center;
        }
      }
    }

    &Title,
    &Caption {
      z-index: 2;
      position: relative;
    }

    &Title {
      margin-bottom: 16px;
      color: #121E29;
      font-family: 'Gilroy';
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media (max-width: 1023px) {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
  }

  .swiper {
    padding-bottom: 64px;

    :global .swiper-wrapper {
      display: grid;
      gap: 26px;
      grid-template-columns: calc(1300px / 3 * 2 - 13px) calc(1300px / 3 - 13px) calc(1300px / 3 * 2 - 13px) calc(1300px / 3 - 13px) calc(1300px / 3 - 13px) calc(1300px / 3 * 2 - 13px);

      @media (max-width: 1023px) {
        display: flex;
        gap: 0;

        > * {
          width: 360px;
        }
      }

      @media (max-width: 767px) {

        > * {
          width: calc(100vw -  32px);
        }
      }
    }
  }
</style>